import type { FC, ReactNode } from 'react';
import React, { useContext, useEffect, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';

import AkButton from '@atlaskit/button/custom-theme-button';
import type { Appearance } from '@atlaskit/button/types';
import AkPremiumIcon from '@atlaskit/icon/core/migration/premium';
import { token } from '@atlaskit/tokens';
import { N0 } from '@atlaskit/theme/colors';

import { type DialogsContainerProps } from '@confluence/dialogs';
import { useDialogs } from '@confluence/dialogs/entry-points/useDialogs';
import {
	ExperienceTrackerContext,
	stopExperienceOnError,
	UPSELL_EDITION_PREMIUM_EXPERIENCE,
	UPSELL_EDITION_UPFLOW_EXPERIENCE,
} from '@confluence/experience-tracker';
import { ErrorDisplay } from '@confluence/error-boundary';
import { useSessionData } from '@confluence/session-data';
import {
	MpucupFreeToStandardFeatureGateModal,
	type MpucupFreeToStandardFeatureGateModalProps,
} from '@confluence/experiment-mpucup-free-to-standard-feature-gates';

import { UP_FLOW_EP_MESSAGE_ID } from '../constants';
import {
	CommerceDeepLinksTargetEdition,
	useCommerceDeepLinks,
} from '../CommerceUtils/useCommerceDeepLinks';
import { GradientCTAButton } from '../GradientLooknFeel/GradientCTAButton';
import { GradientSubtleLinkButton } from '../GradientLooknFeel/GradientSubtleLinkButton';
import { useConfluenceMpucupStandardFgsExperiment } from '../experiment-mpucup-standard-fgs/useConfluenceMpucupStandardFgsExperiment';

import { ConfluenceEdition } from './__types__/waitForEditionChangeQuery';
import { UpFlowCommerceRouterLoadable } from './UpFlowCommerceRouterLoadable';
import { TimeoutError, waitForEditionChange } from './waitForEditionChange';
import { calculateTestId } from './calculateTestId';
import { useEditionsAnalyticsContext } from './AnalyticsProvider';

export type UpgradeEditionButtonProps = {
	analyticsProps: {
		actionSubjectId: string;
		attributes: any;
		source: string;
	};
	appearance?: Appearance;
	targetEdition?: ConfluenceEdition;
	children?: ReactNode;
	onUpFlowOpen?: Function;
	onUpgradeButtonClick?: Function;
};

const i18n = defineMessages({
	tryItFree: {
		id: 'change-edition.upgrade.try.free',
		defaultMessage: 'Try it free',
		description: 'The text of the button/link which starts the user onto the path of free trial',
	},
	tryItNow: {
		id: 'change-edition.upgrade.try.now',
		defaultMessage: 'Try it now',
		description: 'The text of the button/link which starts the user onto the path of upgrade',
	},
	tryPremium: {
		id: 'change-edition.upgrade.try.premium',
		defaultMessage: 'Try Premium',
		description:
			'The text of the button/link which starts the user onto the path of upgrade to Premium edition',
	},
});

const editionMap: Record<ConfluenceEdition, CommerceDeepLinksTargetEdition> = {
	[ConfluenceEdition.FREE]: CommerceDeepLinksTargetEdition.FREE,
	[ConfluenceEdition.STANDARD]: CommerceDeepLinksTargetEdition.STANDARD,
	[ConfluenceEdition.PREMIUM]: CommerceDeepLinksTargetEdition.PREMIUM,
};

const determineButtonComponent = (appearance: Appearance) => {
	if (appearance === 'primary') {
		return GradientCTAButton;
	}

	if (appearance === 'subtle-link') {
		return GradientSubtleLinkButton;
	}

	return AkButton;
};

const MpucupModal: FC<MpucupFreeToStandardFeatureGateModalProps & DialogsContainerProps> = ({
	onClose = () => {},
	touchpointId,
	feature,
	changeOfferingUrl,
	analyticAttributes,
}) => {
	const [showModal, setShowModal] = useState(true);

	const handleCloseClick = () => {
		setShowModal(false);
		onClose();
	};

	if (!showModal) {
		return null;
	}

	return (
		<MpucupFreeToStandardFeatureGateModal
			touchpointId={touchpointId}
			feature={feature}
			onClose={handleCloseClick}
			changeOfferingUrl={changeOfferingUrl}
			analyticAttributes={analyticAttributes}
		/>
	);
};

/**
 * @deprecated Please reach out to #cc-editions for details before use. Please read https://hello.atlassian.net/wiki/spaces/CE2/pages/2797298237/Contribution+Consumption+Guidelines+For+Common+Feature+Gate+Upsell+Component
 */
export const UpgradeEditionButton: FC<UpgradeEditionButtonProps> = ({
	analyticsProps: { actionSubjectId, attributes, source },
	appearance = 'primary',
	children,
	onUpFlowOpen,
	targetEdition = ConfluenceEdition.STANDARD,
	onUpgradeButtonClick,
}: UpgradeEditionButtonProps) => {
	const [openUpFlow, setOpenUpFlow] = useState<boolean>(false);

	const [pollingError, setPollingError] = useState<Error | undefined>();
	const { cloudId, edition: currentEdition, userId } = useSessionData();
	const { createAnalyticsEvent } = useEditionsAnalyticsContext();
	const experienceTracker = useContext(ExperienceTrackerContext);

	const { showModal } = useDialogs();

	// free -> standard: upflow spa;
	// free/standard -> premium: adminhub
	const showUpFlow = targetEdition === ConfluenceEdition.STANDARD;

	const { isEnrolled, upgradeUrl } = useConfluenceMpucupStandardFgsExperiment(!showUpFlow);

	const showConfluenceMpucupStandardFgModal = Boolean(isEnrolled && upgradeUrl);

	const {
		commerceBackendName,
		planConsentDeepLink,
		errorMessage: commerceDeepLinksError,
	} = useCommerceDeepLinks({
		targetEdition: editionMap[targetEdition],
		skipDeepLinks: showUpFlow,
	});

	useEffect(
		() => () => {
			// 1. With upflow enabled, when the user clicks on the upgrade CTA, the
			//    upflow dialog opens and this component unmounts; the upsell experience
			//    continues and experience tracking is subsequently handled by the
			//    UpFlow component.
			// or
			// 2. With upflow DISabled, unmounting here simply means user navigated away
			//    from the upsell UI and we DO want to signal an aborted experience.
			if (!showUpFlow) {
				experienceTracker.abort({
					name: UPSELL_EDITION_PREMIUM_EXPERIENCE,
					reason: 'Canceled by user',
				});
			}
		},
		[experienceTracker, showUpFlow],
	);

	useEffect(() => {
		// Using a TRACK event for tacking the traffic to the UpgradeEditionButton.
		// This will help us gauage Commerce API traffic to compute the deep links.
		createAnalyticsEvent({
			type: 'sendTrackEvent',
			data: {
				action: 'rendered',
				actionSubject: 'upgradeEditionButton',
				actionSubjectId,
				source,
				attributes: {
					...attributes,
					currentEdition,
					targetEdition,
					...(isEnrolled ? { showConfluenceMpucupStandardFgModal } : {}),
				},
			},
		}).fire();
		// We only need this to fire once, when the component mounts
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const fireClickAnalytics = () => {
		if (attributes) {
			attributes.currentEdition = currentEdition;
			attributes.targetEdition = targetEdition;
			attributes.commerceBackend = commerceBackendName;
			if (isEnrolled) {
				attributes.showConfluenceMpucupStandardFgModal = showConfluenceMpucupStandardFgModal;
			}
		}

		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'link',
				actionSubjectId,
				source,
				attributes,
			},
		}).fire();

		if (commerceDeepLinksError) {
			createAnalyticsEvent({
				type: 'sendOperationalEvent',
				data: {
					action: 'errored',
					actionSubject: 'useCommerceDeepLinks',
					actionSubjectId,
					source,
					attributes: {
						...attributes,
						currentEdition,
						targetEdition,
						upsellDeepLink: planConsentDeepLink ? planConsentDeepLink.split('?')[0] : undefined,
						errorMessage: commerceDeepLinksError,
					},
				},
			}).fire();
		}
	};

	const onTryWithoutUpFlowClick = async () => {
		if (onUpgradeButtonClick) {
			onUpgradeButtonClick();
		}

		// start polling and reload the page when done
		try {
			const updatedEdition = await waitForEditionChange(currentEdition);
			experienceTracker.succeed({
				name: UPSELL_EDITION_PREMIUM_EXPERIENCE,
				attributes: {
					currentEdition,
					targetEdition: updatedEdition,
				},
			});

			// Default behavior
			window.location.reload();
		} catch (error) {
			if (error === TimeoutError) {
				// We don't expect the user to always proceed to upgrade in Admin Hub after seeing the estimated billing info.
				experienceTracker.abort({
					name: UPSELL_EDITION_PREMIUM_EXPERIENCE,
					reason: 'Possibly canceled by user',
				});
			} else {
				stopExperienceOnError(UPSELL_EDITION_PREMIUM_EXPERIENCE, error);
				setPollingError(error);
			}
		}
	};

	const onUpFlowClose = () => {
		setOpenUpFlow(false);
	};

	const onTryButtonClick = () => {
		// we always want to fire both the exposure event as well as the click event
		fireClickAnalytics();

		if (!showConfluenceMpucupStandardFgModal) {
			// start the experience tracker
			experienceTracker.start({
				name: showUpFlow ? UPSELL_EDITION_UPFLOW_EXPERIENCE : UPSELL_EDITION_PREMIUM_EXPERIENCE,
				attributes: {
					currentEdition,
					linkClicked: actionSubjectId,
					source,
					targetEdition,
					showConfluenceMpucupStandardFgModal,
				},
			});
		}

		// then handle the click behaviour based on FF
		if (showConfluenceMpucupStandardFgModal) {
			showModal(MpucupModal, {
				touchpointId: 'upgradeEditionButton',
				feature: source,
				changeOfferingUrl: upgradeUrl!,
				analyticAttributes: attributes,
			});
		} else if (showUpFlow) {
			setOpenUpFlow(true);
		} else {
			void onTryWithoutUpFlowClick();
		}
	};

	const buttonProps = {
		appearance,
		className: 'primaryButton',
		href: showUpFlow ? undefined : planConsentDeepLink,
		onClick: onTryButtonClick,
		target: showUpFlow ? undefined : '_blank',
		iconBefore:
			appearance === 'primary' ? (
				<AkPremiumIcon label="" color={token('color.icon.inverse', N0)} LEGACY_size="small" />
			) : undefined,
		testId: calculateTestId({ source, actionSubjectId }),
	};
	const buttonChildren = children || <FormattedMessage {...i18n.tryItFree} />;

	const ButtonComponent = determineButtonComponent(appearance);

	return (
		<>
			{pollingError ? <ErrorDisplay error={pollingError} /> : null}
			<ButtonComponent {...buttonProps}>{buttonChildren}</ButtonComponent>
			{showUpFlow && openUpFlow ? (
				<UpFlowCommerceRouterLoadable
					canChangeEdition // this component should only ever be used for site admins
					cloudId={cloudId}
					currentEdition={currentEdition || undefined}
					atlassianAccountId={userId || undefined}
					flow="upgrade"
					product="confluence"
					targetEdition={targetEdition}
					touchpointId={source}
					isExperienceTrackerEnabled
					onClose={
						// Note: onUpFlowOpen function closes the parent component which
						// means we don't need to trigger the onClose.
						onUpFlowOpen ? undefined : onUpFlowClose
					}
					onOpen={onUpFlowOpen}
					epMessageId={UP_FLOW_EP_MESSAGE_ID}
				/>
			) : null}
		</>
	);
};
